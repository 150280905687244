<template>
    <div class="row p-0 m-0">
            <div class="input-group  p-0">
                <input type="text" class="form-control remove-edges search-bar-border"
                 placeholder="Search task"  />
                <button class="btn btn-flat-secondary remove-edges search-bar-border"  id="searchBtn" type="button">
                    <i class="bi bi-search bs-icon-btn"  ></i>
                </button>
            </div>

            

            <ul>
                <li class="row">
                    <div class="col-10" >
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" style="width:1.2rem;height:1.2rem" id="customCheck13" />
                                <label class="orm-check-label" for="customCheck13">
                                    Conduct a mini awareness meeting regarding health care.
                                </label>
                            </div>

                    </div>
                    <div class="col-2" >
                        <small class="text-nowrap text-muted me-0 " style="float:right">Sept 05</small>
                    </div>
                </li>
                <li class="row">
                    <div class="col-10" >
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" style="width:1.2rem;height:1.2rem" id="customCheck13" />
                                <label class="orm-check-label" for="customCheck13">
                                    Conduct a mini awareness meeting regarding health care.
                                </label>
                            </div>

                    </div>
                    <div class="col-2" >
                        <small class="text-nowrap text-muted me-0 " style="float:right">Sept 05</small>
                    </div>
                </li>
                <li class="row">
                    <div class="col-10" >
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" style="width:1.2rem;height:1.2rem" id="customCheck13" />
                                <label class="orm-check-label" for="customCheck13">
                                    Conduct a mini awareness meeting regarding health care.
                                </label>
                            </div>

                    </div>
                    <div class="col-2" >
                        <small class="text-nowrap text-muted me-0 " style="float:right">Sept 05</small>
                    </div>
                </li>
            </ul>
    </div>
</template>

<script>
export default{

}
</script>

<style scoped>
/* #7367f0; */

ul{
    list-style:none;
    padding: 0rem;
    margin-bottom: 0rem;
}

ul li{
    padding:1rem;
    border-style:solid;
    border-color:#ebe9f1;
    border-width:0px 0px 1px 0px;
}




.search-bar-border{
  border-style:solid;
  border-color:#ebe9f1;
  border-width:0px 0px 1px 0px;
}

.search-bar-active{
    border-color:#7367f0;
}
</style>
